import React, { useRef } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { Routes } from '../constants/Routes';
import SEO from '../components/Seo';
import Title from '../components/title';
import useWindowSize from '../hooks/useWindowSize';
import Icon from '../components/Icon';
import Lightbox from '../components/Lightbox';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const KursTemplate = ({ data: { contentfulKurs } }) => {
  const { width } = useWindowSize();
  const ref = useRef<HTMLDivElement>();

  return (
    <Layout location={Routes.ERWACHSENE}>
      <SEO title={contentfulKurs.titel} />
      {width && (
        <div className="mt-8 relative">
          <Link
            className="block md:absolute md:top-0 md:left-0 md:-mt-4"
            to={contentfulKurs.kategorie.toLowerCase() === 'erwachsene' ? Routes.ERWACHSENE : Routes.KINDER}
          >
            <Icon className="h-16 w-16 -ml-2 md:w-24 md:h-24 md:ml-4" name="arrow_left" />
          </Link>
          <Title className="md:mt-0 mb-4 text-center">{contentfulKurs.titel}</Title>
          <div>
            <div className="mt-8 transition-all transition-250 shadow-lg hover:shadow-xl flex flex-col lg:flex-row lg:w-11/12 lg:mx-auto xl:w-9/12 ">
              <div className="w-full lg:w-5/12" ref={ref}>
                <Lightbox
                  galleryButtonVisible={false}
                  showLeftRightButtons={contentfulKurs.bilder.length > 1}
                  youtubeUrls={[]}
                  images={contentfulKurs.bilder.map((b: any) => b.fluid)}
                />
              </div>
              <div className="markdown-body px-6 mt-6 lg:w-7/12 lg:mt-0 lg:px-12">{documentToReactComponents(contentfulKurs.beschreibung.json)}</div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default KursTemplate;

export const query = graphql`
  query($id: String!) {
    contentfulKurs(id: { eq: $id }) {
      titel
      kategorie
      beschreibung {
        json
      }
      bilder {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
